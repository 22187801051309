import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  initialColorMode: 'light',
  useSystemColorMode: false,

  fonts: {
    heading: 'definition, sans-serif',
    body: 'definition, sans-serif',
  },
  sizes: {
    container: {
      xxl: '1600px',
      xl: '1440px',
    },
  },
  breakpoints: {
    xxl: '1600px',
    xl: '1440px',
  },
  shadows: {
    smoothshadow:
      '0px 2.767256498336792px 2.2138051986694336px 0px rgba(0, 0, 0, 0.02), 0px 6.650102138519287px 5.32008171081543px 0px rgba(0, 0, 0, 0.03), 0px 12.521552085876465px 10.017241477966309px 0px rgba(0, 0, 0, 0.04), 0px 22.3363094329834px 17.869047164916992px 0px rgba(0, 0, 0, 0.04), 0px 41.777610778808594px 33.422088623046875px 0px rgba(0, 0, 0, 0.05), 0px 100px 80px 0px rgba(0, 0, 0, 0.07);',
  },
  fontSizes: {
    xxs: '0.625rem',
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1.0625rem',
    lg: '1.3125rem',
    xl: '1.5rem',
    '2xl': '1.75rem',
    '3xl': '2.25rem',
    '4xl': '2.625rem',
    '5xl': '3.5rem',
    '6xl': '4rem',
    '7xl': '4.5rem',
    '8xl': '6rem',
    '9xl': '8rem',
    '10xl': '10rem',
    '11xl': '16rem',
  },
  lineHeights: {
    undercut: '0.8',
    normal: 'normal',
    none: 1,
    shorter: 1.25,
    short: 1.375,
    base: 1.5,
    tall: 1.625,
    taller: '2',
    3: '.75rem',
    4: '1rem',
    5: '1.25rem',
    6: '1.5rem',
    7: '1.75rem',
    8: '2rem',
    9: '2.25rem',
    10: '2.5rem',
  },
  letterSpacings: {
    tighter: '-0.05em',
    tight: '-0.025em',
    normal: '0',
    wide: '0.025em',
    wider: '0.05em',
    widest: '0.1em',
  },
  components: {
    Heading: {
      baseStyle: {
        fontWeight: 'normal',
        lineHeight: 'undercut',
        letterSpacing: 'normal',
      },
    },
    Text: {
      baseStyle: {
        fontWeight: 'normal',
        lineHeight: 'shorter',
        letterSpacing: 'tight',
      },
    },

    Button: {
      padding: 0,
      baseStyle: {
        borderRadius: '0',
        paddingLeft: '2rem',
      },
      variants: {
        solid: {
          padding: '1rem 2.7rem  1.7rem 2rem',
          display: 'block',
          fontSize: 'xs',
          letterSpacing: 'widest',
          border: '1px solid ',
          background: 'brand.blu.300',
          borderColor: 'brand.blu.300',
          borderRadius: 'sm',
          color: 'brand.gray.1000',
          transition: 'all .25s ease-in-out',
          _hover: {
            color: 'brand.blu.300',
            background: 'transparent',
            transition: 'all .125s ease-in-out',
            border: '1px solid ',
          },
        },
        small: {
          padding: '0.7rem ',
          paddingTop: '0.7rem ',
          display: 'block',
          fontSize: 'xxs',
          letterSpacing: 'widest',
          border: '1px solid ',
          background: 'brand.blu.300',
          borderColor: 'brand.blu.300',
          borderRadius: 'md',
          color: 'brand.gray.1000',
          transition: 'all .25s ease-in-out',
          _hover: {
            color: 'white',
            background: 'transparent',
            transition: 'all .125s ease-in-out',
            border: '1px solid ',
          },
        },
        ghost: {
          padding: '1rem 2.7rem  1.7rem 2rem',
          display: 'block',
          fontSize: 'xs',
          letterSpacing: 'widest',
          border: '1px solid ',

          borderColor: 'brand.blu.300',
          borderRadius: 'sm',
          color: 'brand.blu.300',
          transition: 'all .25s ease-in-out',
          _hover: {
            color: 'brand.gray.1000',
            background: 'transparent',
            transition: 'all .125s ease-in-out',
            border: '1px solid ',
          },
        },
        ghostwhite: {
          padding: '1rem 2.7rem  1.7rem 2rem',
          display: 'block',
          fontSize: 'xs',
          letterSpacing: 'widest',
          border: '1px solid ',
          borderColor: 'white',
          borderRadius: 'sm',
          color: 'white',
          transition: 'all .25s ease-in-out',
          _hover: {
            color: 'brand.blu.1000',
            background: 'transparent',
            transition: 'all .125s ease-in-out',
            border: '1px solid ',
          },
        },
      },
    },
    Container: {
      variants: {
        layoutContainer: {
          maxW: {
            base: 'calc(100% - 4rem)',
            xl: 'min(1480px,calc(100% - 15rem))',
          },
          boxSizing: 'border-box',
          py: 0,
          px: { base: '0', xl: '0', xxl: '0' },
        },
      },
    },
    Link: {
      baseStyle: {
        _hover: {
          textDecoration: 'none',
        },
      },
      variants: {
        navlink: {
          textDecoration: 'none',
          _hover: { textDecoration: 'underline' },
        },
      },
    },
  },
  colors: {
    brand: {
      blu: {
        50: '#070A8A',
        100: '#171ECA',
        300: '#1750F6',
        400: '#3983F6',
        500: '#5AC5F0',
        900: '#68E0E6',
        1000: '#83F2D4',
      },

      gray: {
        100: '#1E1F24',
        200: '#27292F',
        300: '#31333A',
        400: '#444750',
        500: '#676C79',
        600: '#9095A2',
        700: '#A6AAB5',
        800: '#BABDC5',
        900: '#DBDDE1',
        950: '#E6E8EA',
        1000: '#F8F8FA',
        almostwhite: '#F8F8FA',
      },
      blugray: '#28263e',
      blugrayd: '#464C61',
      orange: '#ff5500',
      yellow: '#ffbf00',
      blue: '#5500ff',
      red: {
        pinked: '#ff0066',
        redder: '#ff0040',
      },
    },
  },
});

export default theme;
