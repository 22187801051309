import React from 'react';
import { Global } from '@emotion/react';

const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'definition';
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: url('/static/fonts/TEG-Light.woff2') format('woff2'), url('/static/fonts/TEG-Light.woff') format('woff');
      }

      @font-face {
        font-family: 'definition-bold';
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: url('/static/fonts/TEG-Medium.woff2') format('woff2'), url('/static/fonts/TEG-Medium.woff') format('woff');
      }

     
      `}
  />
);

export default Fonts;
